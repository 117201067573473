//import { AppContext, lightBoxVisibleAction } from 'state/context/appContext'

import BreadcrumbBar from 'components/BreadcrumbBar'
import HaveProject from 'components/haveProject'
import IconMoon from 'components/icons/iconMoon'
import { Link } from 'gatsby'
//import { H2 } from 'components/text'
//import { Link } from 'gatsby'
import Masthead from 'components/mastHead/index.tsx'
import PageContent from 'components/pageContent'
import React from 'react'
//import { sitePaths } from 'config/paths'
import { clientData } from 'models/clientData'
// import { css } from '@emotion/core'
import img from './masthead.jpg'
import { sitePaths } from 'config/paths'
import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'

const BlankClient = styled.div`
  width: 150px;
  margin: 1rem;
  height: 0;
`

const ServiceItem = styled.div`
  max-width: none;
  ${styleConfig.breakPoints.lg} {
    max-width: 33.333333%;
  }
  padding: 2rem 2rem 2rem 2rem;
  .service-icons {
    margin: 0 0 2rem;
  }
  h3 {
    font-size: 1.3rem;
    margin: 0 0 1rem;
  }
`

const AboutPage = (): React.ReactElement => {
  /*const { dispatch } = React.useContext(AppContext)
  const handleOpenGallery = (e: React.MouseEvent | React.KeyboardEvent): void => {
    const photoId = Number(e.currentTarget.getAttribute('data-photo-id'))
    const Images = ['/img/building.jpg', '/img/building2.jpg']
    dispatch(lightBoxVisibleAction(true, Images, photoId))
  }*/
  return (
    <>
      <Masthead backgroundImage={img}>
        <h1 className="lg:text-6xl text-5xl text-center">About</h1>
      </Masthead>
      <div className="flex flex-col flex-grow">
        <BreadcrumbBar>
          <BreadcrumbBar.Content>
            <li>
              <a href="#whatwedo">What We Do</a>
            </li>
            <li>
              <a href="#clients">Clients</a>
            </li>
          </BreadcrumbBar.Content>
        </BreadcrumbBar>

        <section id="whatwedo" className="bg-gray-200">
          <PageContent className="mb-20 my-3 p-6 ">
            <h2 className="text-center font-body text-5xl font-thin text-gray-700 mb-10 mt-10 fadeInUp animated">What We Do</h2>

            <p className="fadeInUp animated py-3">
              We offer professional website application development. Our team offers a strong in depth knowledge and experience in building applications and we&apos;ve{' '}
              <a href="#clients">worked with</a> some of Canada&apos;s largest corporate institutions and leading edge marketing agencies.
            </p>
            <p className="fadeInUp animated py-3">
              Our developers can code your new application or website in the latest web technologies using industry standard best practices and procedures. We’ll be there to help you through the
              critical design and planning process and help you choose a web strategy that suits your business needs.
            </p>
            <p className="fadeInUp animated py-3">
              <Link to={sitePaths.appLinks.contact}>Talk to us</Link> about helping your team make your next project a success too.
            </p>
          </PageContent>
        </section>

        <section className="bg-white py-20">
          <PageContent className="p-4">
            <div className="flex flex-row flex-wrap ">
              <ServiceItem className="fadeInUp animated">
                <div className="service-icons">
                  <IconMoon icon="browser" className="text-blue-700" size="80" />
                </div>
                <h3>Web Development</h3>
                <p>Our main service is providing leading edge internet application developement using open source tools</p>
              </ServiceItem>
              <ServiceItem className="fadeInUp animated">
                <div className="service-icons">
                  <IconMoon icon="tools" className="text-blue-700" size="80" />
                </div>
                <h3>UX Design</h3>
                <p>We build user friendly interfaces for our applications. With experience for multiple platforms and screensizes</p>
              </ServiceItem>
              <ServiceItem className="fadeInUp animated">
                <div className="service-icons">
                  <IconMoon icon="mobile" className="text-blue-700" size="80" />
                </div>
                <h3>Mobile Apps</h3>
                <p>Our apps are made using Responsive CSS, HTML, JS and CSS and deployed for both Android and iOS</p>
              </ServiceItem>
              <ServiceItem className="fadeInUp animated">
                <div className="service-icons">
                  <IconMoon icon="facebook" className="text-blue-700" size="80" />
                  &nbsp;
                  <IconMoon icon="twitter" className="text-blue-700" size="80" />
                </div>
                <h3>Social Apps</h3>
                <p>Our apps are social. We use Facebook, Twitter and other APIs integration into our applications</p>
              </ServiceItem>
              <ServiceItem className="fadeInUp animated">
                <div className="service-icons">
                  <IconMoon icon="desktop" className="text-blue-700" size="80" />
                </div>
                <h3>Desktop Apps</h3>
                <p>
                  We build Desktop Apps with{' '}
                  <a href="http://electron.atom.io" target="_blank" rel="noopener noreferrer">
                    Electron
                  </a>
                  . Your desktop app is made with web technologies like JavaScript, HTML and CSS
                </p>
              </ServiceItem>
              <ServiceItem className="fadeInUp animated">
                <div className="service-icons">
                  <IconMoon icon="tools2" className="text-blue-700" size="80" />
                </div>
                <h3>REST APIs</h3>
                <p>We build REST APIs that provide programmatic access to read and write to your company data</p>
              </ServiceItem>
              <ServiceItem className="fadeInUp animated">
                <div className="service-icons">
                  <IconMoon icon="chat" className="text-blue-700" size="80" />
                </div>
                <h3>Consulting</h3>
                <p>We offer consulting for companies at all stages of project development.</p>
              </ServiceItem>
            </div>
            <div className="text-center pt-16">
              <a className="btn fadeInUp animated block lift" href="/portfolio">
                View our portfolio
              </a>
            </div>
          </PageContent>
        </section>

        <section id="clients" className="bg-gray-200 py-10">
          <PageContent className="p-6">
            <div className="text-center">
              <h2 className="font-body text-5xl font-thin text-gray-700 mb-10 mt-10 fadeInUp animated">Clients</h2>
              <p className="fadeInUp animated py-3">This is a brief list of some of the companies we&apos;ve worked on projects for.</p>
            </div>

            <div className="flex flex-wrap flex-row w-full justify-around py-10">
              {clientData.clients.map((client, mapID): React.ReactNode | void => (
                <img key={mapID} className="rounded-lg shadow-md m-4 img-responsive animated fadeIn" src={`${client.imagePath}`} alt={client.altImage} />
              ))}
              <BlankClient />
              <BlankClient />
              <BlankClient />
            </div>
          </PageContent>
        </section>
      </div>
      <HaveProject />
    </>
  )
}

export default AboutPage
