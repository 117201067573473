export interface IClient {
  name: string
  description: string
  key: string
  imagePath: string
  altImage: string
  link?: string
  internalLink?: boolean
}
export interface IClientData {
  photoPath: string
  clients: IClient[]
}

export const clientData: IClientData = {
  photoPath: '/img/portfolio/gallery',
  clients: [
    { key: 'ibm', name: 'IBM', description: '', imagePath: '/img/clientimages/ibm.jpg', altImage: 'IBM', link: 'https://www.ibm.com' },
    { key: 'gnr8r', name: 'GNR8R Marketing Group Inc', description: '', imagePath: '/img/clientimages/GNR8R.jpg', altImage: 'GNR8R Marketing Group Inc', link: 'https://gnr8r.ca' },
    { key: 'bayview', name: 'Bayview Flowers', description: '', imagePath: '/img/clientimages/bayviewlogo.jpg', altImage: 'Bayview Flowers' },
    { key: 'experis', name: 'Experis', description: '', imagePath: '/img/clientimages/experis.jpg', altImage: 'Experis' },
    { key: 'flightcentre', name: 'Flight Centre Travel Group', description: '', imagePath: '/img/clientimages/ibm.jpg', altImage: 'Flight Centre' },
    { key: 'maclaren', name: 'MacLaren McCann', description: '', imagePath: '/img/clientimages/maclaren.jpg', altImage: 'MacLaren McCann' },
    { key: 'zonetail', name: 'ZoneTail', description: '', imagePath: '/img/clientimages/zonetail.jpg', altImage: 'ZoneTail' },
    { key: 'bell', name: 'Bell Canada', description: '', imagePath: '/img/clientimages/bell.jpg', altImage: 'Bell Canada', link: 'https://www.bell.ca' },
    { key: 'innocean', name: 'Innocean Worldwide', description: '', imagePath: '/img/clientimages/innocean.jpg', altImage: '' },
    { key: 'data', name: 'DATA Group of Companies', description: '', imagePath: '/img/clientimages/datagroup.jpg', altImage: 'DATA Group of Companies' },
    { key: 'ontarioflowers', name: 'Ontario Flower Growers', description: '', imagePath: '/img/clientimages/ofg.jpg', altImage: 'Ontario Flower Growers', link: 'https://www.ontarioflowers.com' },
    { key: 'bmo', name: 'Bank of Montreal', description: '', imagePath: '/img/clientimages/bmo.jpg', altImage: 'Bank of Montreal' },
    { key: 'rogers', name: 'Rogers Communications', description: '', imagePath: '/img/clientimages/rogers.jpg', altImage: 'Rogers Communications' },
    { key: 'mmarketing', name: 'M Marketing Inc.', description: '', imagePath: '/img/clientimages/mmarketing.jpg', altImage: 'M Marketing Inc.' },
    { key: 'alwind', name: 'Alwind Industries', description: '', imagePath: '/img/clientimages/alwind.jpg', altImage: 'Alwind Industries', link: 'https://www.alwind.com' },
    { key: 'nestle', name: 'Nestle', description: '', imagePath: '/img/clientimages/nestle.jpg', altImage: 'Nestle' },
    { key: 'td', name: 'TD', description: '', imagePath: '/img/clientimages/td.jpg', altImage: 'TD' },
    { key: 'manulife', name: 'Manulife', description: '', imagePath: '/img/clientimages/manulife.jpg', altImage: 'Manulife' },
    { key: 'ogilvy', name: 'Ogilvy', description: '', imagePath: '/img/clientimages/ogilvy.jpg', altImage: 'Ogilvy' },
    { key: 'McCain', name: 'McCain', description: '', imagePath: '/img/clientimages/mccain.jpg', altImage: 'McCain' },
    { key: 'OnX', name: 'OnX', description: '', imagePath: '/img/clientimages/onx.jpg', altImage: 'OnX' },
    { key: 'sympatico', name: 'Sympatico', description: '', imagePath: '/img/clientimages/sympatico.jpg', altImage: 'Sympatico' },
    { key: 'resoucemedia', name: 'Resouce Media', description: '', imagePath: '/img/clientimages/resource.jpg', altImage: 'Resouce Media' },
    { key: 'vpg', name: 'Virtually Perfect Golf', description: '', imagePath: '/img/clientimages/vpg.jpg', altImage: 'Virtually Perfect Golf' },
    { key: 'ATI', name: 'ATI', description: '', imagePath: '/img/clientimages/ati.jpg', altImage: 'ATI' },
    { key: 'eagle', name: 'Eagle Resources', description: '', imagePath: '/img/clientimages/eagle.jpg', altImage: 'Eagle Resources' },
    { key: 'Webfeat', name: 'Webfeat', description: '', imagePath: '/img/clientimages/webfeat.jpg', altImage: 'Webfeat' },
    { key: 'MoreCreative', name: 'MoreCreative', description: '', imagePath: '/img/clientimages/morecreative.jpg', altImage: 'MoreCreative' },
    { key: 'Goldcorp', name: 'Goldcorp', description: '', imagePath: '/img/clientimages/goldcorp.jpg', altImage: 'Goldcorp' },
    { key: 'glaxo', name: 'Glaxo Smith Kline', description: '', imagePath: '/img/clientimages/glaxo.jpg', altImage: 'Glaxo Smith Kline' },
    { key: 'Sonar', name: 'Sonar', description: '', imagePath: '/img/clientimages/sonar.jpg', altImage: 'Sonar' },
    { key: 'corpworld', name: 'Corpworld', description: '', imagePath: '/img/clientimages/corpworld.jpg', altImage: 'Corpworld' },
    { key: 'Enerquality', name: 'Enerquality', description: '', imagePath: '/img/clientimages/enerquality.jpg', altImage: 'Enerquality' },
    { key: 'warren', name: 'Warren Sheppell', description: '', imagePath: '/img/clientimages/warren.jpg', altImage: 'Warren Sheppell' },
    { key: 'silverbirch', name: 'Silverbirch Studios', description: '', imagePath: '/img/clientimages/silverbirch.jpg', altImage: 'Silverbirch Studios' },
    { key: 'clickmedia', name: 'Clickmedia', description: '', imagePath: '/img/clientimages/clickmedia.jpg', altImage: 'Clickmedia' },
    { key: 'Applanix', name: 'Applanix', description: '', imagePath: '/img/clientimages/applanix.jpg', altImage: 'Applanix' },
    { key: 'Calisto', name: 'Calisto', description: '', imagePath: '/img/clientimages/calisto.jpg', altImage: 'Calisto' },
    { key: 'redwood', name: 'Redwood E-Learning', description: '', imagePath: '/img/clientimages/redwood.jpg', altImage: 'Redwood E-Learning' },
  ],
}
