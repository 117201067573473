import IcoMoon from 'react-icomoon'
import React from 'react'
import iconSet from './selection.json'

export enum MoonIcons {
  mobile = 'mobile',
  chat = 'chat',
  tools = 'tools',
  tools2 = 'tools2',
  twitter = 'twitter',
  facebook = 'facebook',
  desktop = 'desktop',
  tablet = 'tablet',
  browser = 'browser',
}
interface IIconMoonProps {
  icon?: keyof typeof MoonIcons
  size?: number | string
  color?: string
  style?: object
  className?: string
  disableFill?: boolean
  removeInlineStyle?: boolean
}
const IconMoon = ({ ...props }: IIconMoonProps): React.ReactElement => {
  return <IcoMoon iconSet={iconSet} {...props} />
}

export default IconMoon
